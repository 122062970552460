// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //* For Prod
  apiUrl: "https://prodapispice.carterx.in",
  carterXUrl: "https://app.carterx.in/index.php?",
  razorPayKey: "rzp_live_LthnWTU5SuA0Hg",

  //* For QA
  // apiUrl: "https://qaapispice.carterporter.in",
  // carterXUrl: "https://hyd.carterx.in/index.php?",
  // razorPayKey: "rzp_test_fOTZDy0HmQGnS7",
};

export const razorPayKey = {
  testKey: "rzp_test_fOTZDy0HmQGnS7",
  productionKey: "rzp_live_LthnWTU5SuA0Hg",
};

export const apis = {
  login: "/api/sj/login",
  fetchBooking: "/api/sj/get-booking?response_type=json",
  getAmount: "r=v3/thirdparty-corporate-api/calculation",
  booking: "r=v3/thirdparty-corporate-api/booking",
};

//* For Prod
export const bagArray = [
  {
    bag: 1,
    arrivalToken: "f7fb3e8fc62151b55652ecc097b9fbaf",
    departureToken: "779f2a42da4f88ce3320e8720035e43e",
  },

  {
    bag: 2,
    arrivalToken: "0c93591d09c898e50fded14cb23750f1",
    departureToken: "d9b984829f67fabcaa8d0491444a5be6",
  },

  {
    bag: 3,
    arrivalToken: "a5b190a40e57bcf3b64bea2650672ee3",
    departureToken: "a03d8684ecb837066f04894ab8776e81",
  },

  {
    bag: 4,
    arrivalToken: "72d6b84401db0c7db065abaeb7000d3b",
    departureToken: "ea7a098e79750abcd38a37004e8609ce",
  },
];

//* For QA
// export const bagArray = [
//   {
//     bag: 1,
//     arrivalToken: "5632a32eac424bfc5248d8d446e160b5",
//     departureToken: "058d9c2050d252447b9b7b6ecd626e23",
//   },

//   {
//     bag: 2,
//     arrivalToken: "5632a32eac424bfc5248d8d446e160b5",
//     departureToken: "058d9c2050d252447b9b7b6ecd626e23",
//   },

//   {
//     bag: 3,
//     arrivalToken: "5ea52c0a12e05c1858835e29a8fa48bd",
//     departureToken: "a0673d1847ed8c762accfbbfc1cdefb0",
//   },

//   {
//     bag: 4,
//     arrivalToken: "66899ce52734254aa3a193b96c6f47ec",
//     departureToken: "3dce143eb4bbc9f3a3c458dacfb0a43d",
//   },
// ];

export const workingCityArry = [
  {
    city: "Bangalore",
    city_id: 1,
    pincode: "560001",
    city_code: "BLR",
    airport_id: "3",
    details:
      "LOCATION AT AIRPORT: 24/4 CarterX kiosk at Arrivals. Opp. Relay store\n&FOR DEPARTURES: Order Delivery up to Airline Check-in counters\n&FOR ARRIVAL:Order Pick up at Luggage Carousel/baggage belts (Domestic and International)\n&LUGGAGE STORAGE: Available&INTER TERMINAL TRANSFER: N/A",
  },
  {
    city: "Hyderabad",
    city_id: 2,
    pincode: "500001",
    city_code: "HYD",
    airport_id: "7",
    details:
      "LOCATION AT AIRPORT: 24/7 CarterX kiosk.\n&FOR DEPARTURES: Delivery till terminal entry gates.\n&FOR ARRIVAL: Bags to be dropped at CarterX kiosk at Arrivals village. Opp. Café Coffee Day.\n&LUGGAGE STORAGE: Available&INTER TERMINAL TRANSFER: Available",
  },
  {
    city: "Mumbai",
    city_id: 3,
    pincode: "400001",
    city_code: "BOM",
    airport_id: "9",
    details:
      "LOCATION AT AIRPORT: 24/7 CarterX kiosk. Opp. Arrivals gate and next to Meru cabs counter.\n&FOR DEPARTURES: Delivery till terminal entry gates.\n&FOR ARRIVAL: Bags to be dropped at CarterX kiosk. Opp. Arrivals gate and next to Meru cabs counter.\n&INTER TERMINAL TRANSFER: Available",
  },
  {
    city: "New Delhi",
    city_id: 5,
    pincode: "110001",
    city_code: "DEL",
    airport_id: "14",
    details:
      "LOCATION AT AIRPORT: CarterX Executive presence with a Signage board.\n&FOR DEPARTURES: Delivery till terminal entry gates.\n&FOR ARRIVAL: Bags to be handed over to CarterX Executive with Signage board. ‘Remove domestic and international’.\n&INTER TERMINAL TRANSFER: Available",
  },
];

// export const checkArray = [
//   {
//     'check': true,
//     'arrivalToken': '66899ce52734254aa3a193b96c6f47ec',
//     'departureToken': '3dce143eb4bbc9f3a3c458dacfb0a43d'
//   },
//   {
//     'check': false,
//     'arrivalToken': 0,
//     'departureToken': 0
//   },
// ]

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
