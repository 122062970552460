import { FAQComponent } from './faq/faq.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { CancelandrefundComponent } from './cancelandrefund/cancelandrefund.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingConfirmationComponent } from './booking-confirmation/booking-confirmation.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { BookingInformationComponent } from './booking-information/booking-information.component';
import { LandingpageComponent } from './landingpage/landingpage.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '', component: LandingpageComponent},
  {path:'booking',component:BookingDetailsComponent},
  {path:'order-confirmation-page', component:BookingConfirmationComponent},
  {path:'booking-information', component:BookingInformationComponent},
  {path:'Cancellation and refund policy', component:CancelandrefundComponent},
  {path:'Terms and Conditions', component:TermsandconditionsComponent},
  {path:'FAQ', component:FAQComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
