import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

// import { FormGroup, FormControl } from '@angular/forms';
// import { Validators } from '@angular/forms';
import { SpicejetService } from "../spicejet.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-landingpage",
  templateUrl: "./landingpage.component.html",
  styleUrls: ["./landingpage.component.scss"],
})
export class LandingpageComponent implements OnInit {
  // name = new FormControl('');
  pnrSearchForm: FormGroup;
  submitted = false;
  pnr: any;
  email: any;
  sig_json: any;
  status: any;
  loader: boolean = false;
  // const control = new FormControl('', Validators.required);

  // pnr: [
  //   '',
  //   [
  //     Validators.required,
  //     Validators.maxLength(50),
  //     Validators.pattern('^[a-zA-Z0-9]*$')
  //   ]
  // ],

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private service: SpicejetService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.pnrSearchForm = this.formBuilder.group({
      pnr: [
        "",
        [
          Validators.required,
          Validators.maxLength(6),
          Validators.pattern("^[a-zA-Z0-9]+"),
        ],
      ],
      email: ["", [Validators.required]],
      // toDo: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]]
    });
  }

  get f() {
    return this.pnrSearchForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.pnrSearchForm.invalid) {
      return;
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.pnrSearchForm.value, null, 4));
    this.login();
  }

  signature: any = "";
  login() {
    this.service.getLogin().subscribe(
      (res) => {
        let sig_json = JSON.parse(res["_body"]);

        localStorage.setItem("pnrValue", this.pnrSearchForm.get("pnr").value);
        localStorage.setItem(
          "emailValue",
          this.pnrSearchForm.get("email").value
        );
        this.signature =
          sig_json["s:Envelope"]["s:Body"]["LogonResponse"]["Signature"];
        localStorage.setItem("signature", this.signature);
        // this.router.navigate(['/booking'])
        // if(localStorage.getItem("signature") || localStorage.getItem("pnrValue") || localStorage.getItem("emailValue")){
        // ;
        // this.service.getBookingDetails(localStorage.getItem("signature"),localStorage.getItem("pnrValue"),localStorage.getItem("emailValue")).subscribe(res =>{
        //   this.sig_json = JSON.parse(res['_body']);
        //   ;
        // })
        // }

        this.loader = true;
        this.service
          .getBookingDetails(
            localStorage.getItem("signature"),
            localStorage.getItem("pnrValue"),
            localStorage.getItem("emailValue")
          )
          .subscribe(
            (res) => {
              this.status = res;
              // if(this.status['status'] == '200'){
              // this.toastr.success('Success');
              this.loader = false;
              this.router.navigate(["/booking"]);
              // }else{
              //   alert("invalid pnr")
              // }
            },
            (err) => {
              // console.log(err);
              // alert("invalid pnr")
              this.loader = false;
              if (res.status != 500)
                this.toastr.error(
                  "PNR does not exist for given combination of email/last name"
                );
            }
          );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // submitBtn(){
  //   this.router.navigate(['/booking'])
  // }
}
