import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ChangeDetectionStrategy,
} from "@angular/core";
import { SpicejetService } from "../spicejet.service";
import { UtilService } from "../util.service";
import { throttleTime } from "rxjs/operators";
import { Router } from "@angular/router";
import {
  bagArray,
  razorPayKey,
  workingCityArry,
  environment,
} from "../../environments/environment";
import _ from "lodash";

//Razorpay get way
declare var Razorpay: any;
declare var $: any;

@Component({
  selector: "app-round-way",
  templateUrl: "./round-way.component.html",
  styleUrls: ["./round-way.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundWayComponent implements OnInit {
  toggle: Boolean = false;
  userName: any;
  HYD: string;
  BOM: string;
  DEL: string;
  BLR: string;

  bagItemList: any = 1;
  // check: Boolean = false;

  departureCityA_id: any = "";
  departureAirportA_id: any;
  departureCityA_pincode: any;

  departureCityD_id: any = "";
  departureAirportD_id: any;
  departureCityD_pincode: any;

  arrivalCityA_id: any = "";
  arrivalAirportA_id: any;
  arrivalCityA_pincode: any;

  arrivalCityD_id: any = "";
  arrivalAirportD_id: any;
  arrivalCityD_pincode: any;

  departureCityAirpotDetails: any = "";
  arrivalCityAirpotDetails: any = "";
  departure_info: any = [];
  arrival_info: any = [];
  @Output() totalAmountChanged: EventEmitter<{
    amount: number;
    userDetails: [];
    arrivalLoader: boolean;
    departureLoader: boolean;
    roundArrivalLoader: boolean;
    roundDepartureLoader: boolean;
    length: boolean;
    round: number;
  }> = new EventEmitter();

  workingCityArry = workingCityArry;

  bagArr = bagArray;

  amount: any;
  checkAll: any = "checked";
  switchToggle: boolean = true;
  order: any;
  selectedBookingData: any = [];
  totalAmount_V: number = 0;

  @Output() AmountOutput: EventEmitter<{}> = new EventEmitter();
  constructor(
    private service: SpicejetService,
    private datePipe: DatePipe,
    private util: UtilService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  bookingDetails: any;
  sig_json: any;
  name: any;
  // trip: any;
  journeyDetailsA: any;
  journeyDetailsD: any;
  arrivalDateA: any;
  arrivalDateD: any;
  departureDateA: any;
  departureDateD: any;
  passengerList: any;
  passengerListItemData: any[] = [];
  legArrivalDateA: any;
  legArrivalDateD: any;
  legDepartureDateA: any;
  legDepartureDateD: any;
  flightDetail: any;

  users_details: any = [];

  // pnr: any;

  ngOnInit() {
    this.util
      .lazyLoadLibrary("https://checkout.razorpay.com/v1/checkout.js")
      .subscribe();
    this.getBookingDetails();
  }

  getBookingDetails() {
    if (this.users_details.length === 0) {
      this.service
        .getBookingDetails(
          localStorage.getItem("signature"),
          localStorage.getItem("pnrValue"),
          localStorage.getItem("emailValue")
        )
        .subscribe((res) => {
          this.sig_json = JSON.parse(res["_body"]);
          this.journeyDetailsA = this.sig_json.journeyDetailsData[0].Segments.Segment;
          this.journeyDetailsD = this.sig_json.journeyDetailsData[1].Segments.Segment;

          this.arrivalDateA = this.journeyDetailsA.STA;
          this.arrivalDateD = this.journeyDetailsD.STA;
          this.departureDateA = this.journeyDetailsA.STD;
          this.departureDateD = this.journeyDetailsD.STD;
          this.passengerList = this.sig_json["passengerData"];
          this.legArrivalDateA = this.journeyDetailsA.Legs.Leg.STA;
          this.legArrivalDateD = this.journeyDetailsD.Legs.Leg.STA;
          this.legDepartureDateA = this.journeyDetailsA.Legs.Leg.STD;
          this.legDepartureDateD = this.journeyDetailsD.Legs.Leg.STD;
          this.flightDetail = this.sig_json.FlightDesignator;

          let bagData = [1, 2, 3, 4, 5, 6, 7, 8];

          if (this.passengerList.length > 0) {
            this.passengerList.map((element) => {
              element.bagItemListLeftTop = 1;
              element.bagItemListLeftBottom = 1;
              element.bagItemListRightTop = 1;
              element.bagItemListRightBottom = 1;
              return this.passengerListItemData.push(element);
            });
          } else {
            this.passengerList.bagItemListLeftTop = 1;
            this.passengerList.bagItemListLeftBottom = 1;
            this.passengerList.bagItemListRightTop = 1;
            this.passengerList.bagItemListRightBottom = 1;
            this.passengerListItemData.push(this.passengerList);
          }
          this.passengerListItemData.map((data) => {
            data.bagDataLeftTop = [];
            data.bagDataLeftTop = bagData;
            data.bagDataRightTop = [];
            data.bagDataRightTop = bagData;
            data.bagDataLeftBottom = [];
            data.bagDataLeftBottom = bagData;
            data.bagDataRightBottom = [];
            data.bagDataRightBottom = bagData;
          });

          this.passengerList = this.passengerListItemData;
          for (let i = 0; i < this.workingCityArry.length; i++) {
            if (
              this.journeyDetailsA.DepartureStation ==
              this.workingCityArry[i].city_code
            ) {
              this.departureCityA_id = this.workingCityArry[i].city_id;
              this.departureCityA_pincode = this.workingCityArry[i].pincode;
              this.departureAirportA_id = this.workingCityArry[i].airport_id;
              this.departureCityAirpotDetails = this.workingCityArry[i].details;
              this.departure_info.push({
                airportDetails: this.departureCityAirpotDetails,
              });
              // localStorage.setItem('departure', this.departure_info.toString());
            }

            if (
              this.journeyDetailsA.ArrivalStation ==
              this.workingCityArry[i].city_code
            ) {
              this.arrivalCityA_id = this.workingCityArry[i].city_id;
              this.arrivalCityA_pincode = this.workingCityArry[i].pincode;
              this.arrivalAirportA_id = this.workingCityArry[i].airport_id;
              this.arrivalCityAirpotDetails = this.workingCityArry[i].details;
              this.arrival_info.push({
                airportDetails: this.arrivalCityAirpotDetails,
              });
            }

            if (
              this.journeyDetailsD.DepartureStation ==
              this.workingCityArry[i].city_code
            ) {
              this.departureCityD_id = this.workingCityArry[i].city_id;
              this.departureCityD_pincode = this.workingCityArry[i].pincode;
              this.departureAirportD_id = this.workingCityArry[i].airport_id;
              this.departureCityAirpotDetails = this.workingCityArry[i].details;
              this.departure_info.push({
                airportDetails: this.departureCityAirpotDetails,
              });
              localStorage.setItem("departure", this.departure_info.toString());
            }

            if (
              this.journeyDetailsD.ArrivalStation ==
              this.workingCityArry[i].city_code
            ) {
              this.arrivalCityD_id = this.workingCityArry[i].city_id;
              this.arrivalCityD_pincode = this.workingCityArry[i].pincode;
              this.arrivalAirportD_id = this.workingCityArry[i].airport_id;
              this.arrivalCityAirpotDetails = this.workingCityArry[i].details;
              this.arrival_info.push({
                airportDetails: this.arrivalCityAirpotDetails,
              });
            }
          }
          this.passengerListItemData.map((items) => {
            this.users_details.push({
              name:
                items.Names.BookingName.FirstName +
                " " +
                items.Names.BookingName.LastName,
              mobile: this.sig_json.bookingContact.HomePhone,
              departure_bag: this.bagArr[0],
              roundway_departure_bag: this.bagArr[0],
              arrival_bag: this.bagArr[0],
              roundway_arrival_bag: this.bagArr[0],
              email: this.sig_json.bookingContact.EmailAddress,
              amount: "",
              arrival_city: this.arrivalCityA_id,
              roundway_arrival_city: this.arrivalCityD_id,
              departure_city: this.departureCityA_id,
              roundway_departure_city: this.departureCityD_id,
              arrival_pincode: this.arrivalCityA_pincode,
              roundway_arrival_pincode: this.arrivalCityD_pincode,
              roundway_departure_pincode: this.departureCityD_pincode,
              arrival_airport: this.arrivalAirportA_id,
              roundway_arrival_airport: this.arrivalAirportD_id,
              departure_airport: this.departureAirportA_id,
              roundway_departure_airport: this.departureAirportD_id,
              bagList_departure: [],
              roundway_bagList_departure: [],
              bagList_arrival: [],
              roundway_bagList_arrival: [],
              departure_bag_gst: "",
              roundway_departure_bag_gst: "",
              arrival_bag_gst: "",
              arrival_bag_amount: 0,
              departure_bag_amount: 0,
              roundway_arrival_bag_amount: 0,
              roundway_departure_bag_amount: 0,
              roundway_arrival_bag_gst: "",
              passengerID: items.PassengerID,
              selected_departure: this.departureCityA_id !== "" ? true : false,
              selected_arrival: this.arrivalCityA_id !== "" ? true : false,
              selected_round_arrival:
                this.arrivalCityD_id !== "" ? true : false,
              selected_round_departure:
                this.departureCityD_id !== "" ? true : false,
            });
          });

          this.calculatingAmout();
        });
    }
  }

  selected(bagData, position, item) {
    if (position === "leftTop") {
      for (let i = 0; i < this.users_details.length; i++) {
        if (item.PassengerID == this.users_details[i].passengerID) {
          this.users_details[i].departure_bag = bagData;
        }
      }
      item.bagItemListLeftTop = bagData.bag;
      // item.bagItemListLeftTop = bagData;
    } else if (position === "rightTop") {
      for (let i = 0; i < this.users_details.length; i++) {
        if (item.PassengerID == this.users_details[i].passengerID) {
          this.users_details[i].arrival_bag = bagData;
        }
      }
      item.bagItemListRightTop = bagData.bag;
    } else if (position === "leftBottom") {
      for (let i = 0; i < this.users_details.length; i++) {
        if (item.PassengerID == this.users_details[i].passengerID) {
          this.users_details[i].roundway_departure_bag = bagData;
        }
      }
      item.bagItemListLeftBottom = bagData.bag;
    } else if (position === "rightBottom") {
      for (let i = 0; i < this.users_details.length; i++) {
        if (item.PassengerID == this.users_details[i].passengerID) {
          this.users_details[i].roundway_arrival_bag = bagData;
        }
      }
      item.bagItemListRightBottom = bagData.bag;
    }
    this.calculatingAmout();
  }

  approxAmountCounter: any = 0;
  calculatedAmount: any = 0;
  priceDetailsRes: any;
  finaljson: any;

  // calculating departure and arrival amount
  counter: any = 0;
  arrivalLoader: any = false;
  departureLoader: any = false;
  roundArrivalLoader: any = false;
  roundDepartureLoader: any = false;
  lengthLoader: any = false;
  length: any = false;
  round_counter: any = 0;
  round_token: any = 0;
  calculatingAmout() {
    this.lengthLoader = false;
    this.calculatedAmount = 0;
    this.round_counter = 0;
    this.round_token = 0;
    this.totalAmountChanged.emit({
      amount: this.calculatedAmount,
      userDetails: this.users_details,
      departureLoader: this.departureLoader,
      arrivalLoader: this.arrivalLoader,
      roundArrivalLoader: this.roundArrivalLoader,
      roundDepartureLoader: this.roundDepartureLoader,
      length: this.round_counter,
      round: this.round_token,
    });
    this.length = this.users_details.length;
    for (let i = 0; i < this.users_details.length; i++) {
      // for departure
      // this.round_token = this.round_token + 1;
      this.departureLoader = true;
      this.arrivalLoader = true;
      this.roundDepartureLoader = true;
      this.roundArrivalLoader = true;
      if (
        this.departureCityA_id != "" &&
        this.users_details[i].selected_departure == true
      ) {
        // set body
        // this.departureLoader = true;
        this.round_token = this.round_token + 1;
        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.departureAirportA_id,
          city_name: this.departureCityA_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.users_details[i].departure_bag.bag,
          pincode: this.departureCityA_pincode,
          service_type: 2, //departure:2 arrival:1
        };
        this.service
          .getBaggaeAmount(
            reqBody,
            this.users_details[i].departure_bag.departureToken
          )
          .subscribe(
            (res) => {
              this.departureLoader = false;
              this.arrivalLoader = true;
              const itemsOrder = [];
              let priceDetailsRes;
              let a = JSON.parse(res["_body"]);
              priceDetailsRes = JSON.parse(res["_body"]);
              let index = i;
              //
              let amount =
                a.price_details.price_with_gst > 0
                  ? a.price_details.price_with_gst
                  : 0;
              this.selectedDataHandler(
                this.users_details[i],
                "departure",
                this.departureAirportA_id,
                amount,
                "leftTop",
                i
              );
              this.calculatedAmount = amount + this.calculatedAmount;
              this.round_counter = this.round_counter + 1;
              this.totalAmountChanged.emit({
                amount: this.calculatedAmount,
                userDetails: this.users_details,
                departureLoader: this.departureLoader,
                arrivalLoader: this.arrivalLoader,
                roundArrivalLoader: this.roundArrivalLoader,
                roundDepartureLoader: this.roundDepartureLoader,
                length: this.round_counter,
                round: this.round_token,
              });
              if (i === this.users_details.length - 1) {
                this.lengthLoader = true;
                this.totalAmountChanged.emit({
                  amount: this.calculatedAmount,
                  userDetails: this.users_details,
                  departureLoader: this.departureLoader,
                  arrivalLoader: this.arrivalLoader,
                  roundArrivalLoader: this.roundArrivalLoader,
                  roundDepartureLoader: this.roundDepartureLoader,
                  length: this.round_counter,
                  round: this.round_token,
                });
              }
              const bagItems = priceDetailsRes.price_details.items;
              this.users_details[i].bagList_departure = [];
              for (const key in bagItems) {
                if (bagItems.hasOwnProperty(key)) {
                  const element = bagItems[key];
                  this.users_details[i].bagList_departure.push({
                    bag_type: key,
                    price: element,
                  });
                }
              }
              this.users_details[i].departure_bag_gst =
                priceDetailsRes.price_details.gst_price;
              this.users_details[i].departure_bag_amount = amount;

              localStorage.setItem(
                "users_details",
                JSON.stringify(this.users_details)
              );
              localStorage.setItem("calculatedAmount", this.calculatedAmount);
            },
            (err) => {
              this.round_counter = this.round_counter + 1;
              this.totalAmountChanged.emit({
                amount: this.calculatedAmount,
                userDetails: this.users_details,
                departureLoader: this.departureLoader,
                arrivalLoader: this.arrivalLoader,
                roundArrivalLoader: this.roundArrivalLoader,
                roundDepartureLoader: this.roundDepartureLoader,
                length: this.round_counter,
                round: this.round_token,
              });
              console.log("err", err);
            }
          );
      }

      // for arrival
      if (
        this.arrivalCityA_id != "" &&
        this.users_details[i].selected_arrival == true
      ) {
        this.round_token = this.round_token + 1;
        // set body
        // this.arrivalLoader = true;

        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.arrivalAirportA_id,
          city_name: this.arrivalCityA_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.users_details[i].arrival_bag.bag,
          pincode: this.arrivalCityA_pincode,
          service_type: 1, //departure:2 arrival:1
        };
        this.service
          .getBaggaeAmount(
            reqBody,
            this.users_details[i].arrival_bag.arrivalToken
          )
          .subscribe(
            (res) => {
              this.arrivalLoader = false;
              this.departureLoader = true;
              const itemsOrder = [];
              let priceDetailsRes;

              let a = JSON.parse(res["_body"]);
              priceDetailsRes = JSON.parse(res["_body"]);
              //
              let amount =
                a.price_details.price_with_gst > 0
                  ? a.price_details.price_with_gst
                  : 0;
              this.selectedDataHandler(
                this.users_details[i],
                "arrival",
                this.arrivalAirportA_id,
                amount,
                "rightTop",
                i
              );
              this.calculatedAmount = amount + this.calculatedAmount;
              this.round_counter = this.round_counter + 1;
              this.totalAmountChanged.emit({
                amount: this.calculatedAmount,
                userDetails: this.users_details,
                departureLoader: this.departureLoader,
                arrivalLoader: this.arrivalLoader,
                roundArrivalLoader: this.roundArrivalLoader,
                roundDepartureLoader: this.roundDepartureLoader,
                length: this.round_counter,
                round: this.round_token,
              });
              if (i === this.users_details.length - 1) {
                this.lengthLoader = true;
                this.totalAmountChanged.emit({
                  amount: this.calculatedAmount,
                  userDetails: this.users_details,
                  departureLoader: this.departureLoader,
                  arrivalLoader: this.arrivalLoader,
                  roundArrivalLoader: this.roundArrivalLoader,
                  roundDepartureLoader: this.roundDepartureLoader,
                  length: this.round_counter,
                  round: this.round_token,
                });
              }
              const bagItems = priceDetailsRes.price_details.items;
              this.users_details[i].bagList_arrival = [];
              for (const key in bagItems) {
                if (bagItems.hasOwnProperty(key)) {
                  const element = bagItems[key];
                  this.users_details[i].bagList_arrival.push({
                    bag_type: key,
                    price: element,
                  });
                }
              }
              this.users_details[i].arrival_bag_gst =
                priceDetailsRes.price_details.gst_price;
              this.users_details[i].arrival_bag_amount = amount;
              localStorage.setItem(
                "users_details",
                JSON.stringify(this.users_details)
              );
              localStorage.setItem("calculatedAmount", this.calculatedAmount);
            },
            (err) => {
              console.log("err", err);
              this.round_counter = this.round_counter + 1;
              this.totalAmountChanged.emit({
                amount: this.calculatedAmount,
                userDetails: this.users_details,
                departureLoader: this.departureLoader,
                arrivalLoader: this.arrivalLoader,
                roundArrivalLoader: this.roundArrivalLoader,
                roundDepartureLoader: this.roundDepartureLoader,
                length: this.round_counter,
                round: this.round_token,
              });
            }
          );
      }

      // for roundway departure
      if (
        this.departureCityD_id != "" &&
        this.users_details[i].selected_round_departure == true
      ) {
        this.round_token = this.round_token + 1;
        // set body
        // this.roundDepartureLoader = true;

        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.departureAirportD_id,
          city_name: this.departureCityD_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.users_details[i].roundway_departure_bag.bag,
          pincode: this.departureCityD_pincode,
          service_type: 2, //departure:2 arrival:1
        };
        this.service
          .getBaggaeAmount(
            reqBody,
            this.users_details[i].roundway_departure_bag.departureToken
          )
          .subscribe(
            (res) => {
              this.roundDepartureLoader = false;
              this.roundArrivalLoader = true;
              const itemsOrder = [];
              let priceDetailsRes;

              let a = JSON.parse(res["_body"]);
              priceDetailsRes = JSON.parse(res["_body"]);
              //
              let amount =
                a.price_details.price_with_gst > 0
                  ? a.price_details.price_with_gst
                  : 0;
              this.selectedDataHandler(
                this.users_details[i],
                "departure",
                this.departureAirportD_id,
                amount,
                "leftBottom",
                i
              );
              this.calculatedAmount = amount + this.calculatedAmount;
              this.round_counter = this.round_counter + 1;
              this.totalAmountChanged.emit({
                amount: this.calculatedAmount,
                userDetails: this.users_details,
                departureLoader: this.departureLoader,
                arrivalLoader: this.arrivalLoader,
                roundArrivalLoader: this.roundArrivalLoader,
                roundDepartureLoader: this.roundDepartureLoader,
                length: this.round_counter,
                round: this.round_token,
              });
              if (i === this.users_details.length - 1) {
                this.lengthLoader = true;
                this.totalAmountChanged.emit({
                  amount: this.calculatedAmount,
                  userDetails: this.users_details,
                  departureLoader: this.departureLoader,
                  arrivalLoader: this.arrivalLoader,
                  roundArrivalLoader: this.roundArrivalLoader,
                  roundDepartureLoader: this.roundDepartureLoader,
                  length: this.round_counter,
                  round: this.round_token,
                });
              }
              const bagItems = priceDetailsRes.price_details.items;
              this.users_details[i].roundway_bagList_departure = [];
              for (const key in bagItems) {
                if (bagItems.hasOwnProperty(key)) {
                  const element = bagItems[key];
                  this.users_details[i].roundway_bagList_departure.push({
                    bag_type: key,
                    price: element,
                  });
                }
              }
              this.users_details[i].roundway_departure_bag_gst =
                priceDetailsRes.price_details.gst_price;
              this.users_details[i].roundway_departure_bag_amount = amount;
              localStorage.setItem(
                "users_details",
                JSON.stringify(this.users_details)
              );
              localStorage.setItem("calculatedAmount", this.calculatedAmount);
            },
            (err) => {
              this.round_counter = this.round_counter + 1;
              this.totalAmountChanged.emit({
                amount: this.calculatedAmount,
                userDetails: this.users_details,
                departureLoader: this.departureLoader,
                arrivalLoader: this.arrivalLoader,
                roundArrivalLoader: this.roundArrivalLoader,
                roundDepartureLoader: this.roundDepartureLoader,
                length: this.round_counter,
                round: this.round_token,
              });
              console.log("err", err);
            }
          );
      }

      // for roundway arrival
      if (
        this.arrivalCityD_id != "" &&
        this.users_details[i].selected_round_arrival == true
      ) {
        this.round_token = this.round_token + 1;
        // this.roundArrivalLoader = true;
        // set body
        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.arrivalAirportD_id,
          city_name: this.arrivalCityD_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.users_details[i].roundway_arrival_bag.bag,
          pincode: this.arrivalCityD_pincode,
          service_type: 1, //departure:2 arrival:1
        };
        this.service
          .getBaggaeAmount(
            reqBody,
            this.users_details[i].roundway_arrival_bag.arrivalToken
          )
          .subscribe(
            (res) => {
              this.roundArrivalLoader = false;
              this.roundDepartureLoader = true;
              const itemsOrder = [];
              let priceDetailsRes;

              let a = JSON.parse(res["_body"]);
              priceDetailsRes = JSON.parse(res["_body"]);
              //
              let amount =
                a.price_details.price_with_gst > 0
                  ? a.price_details.price_with_gst
                  : 0;
              this.selectedDataHandler(
                this.users_details[i],
                "arrival",
                this.arrivalAirportD_id,
                amount,
                "rightBottom",
                i
              );
              this.calculatedAmount = amount + this.calculatedAmount;
              this.round_counter = this.round_counter + 1;
              this.totalAmountChanged.emit({
                amount: this.calculatedAmount,
                userDetails: this.users_details,
                departureLoader: this.departureLoader,
                arrivalLoader: this.arrivalLoader,
                roundArrivalLoader: this.roundArrivalLoader,
                roundDepartureLoader: this.roundDepartureLoader,
                length: this.round_counter,
                round: this.round_token,
              });
              if (i === this.users_details.length - 1) {
                this.lengthLoader = true;
                this.totalAmountChanged.emit({
                  amount: this.calculatedAmount,
                  userDetails: this.users_details,
                  departureLoader: this.departureLoader,
                  arrivalLoader: this.arrivalLoader,
                  roundArrivalLoader: this.roundArrivalLoader,
                  roundDepartureLoader: this.roundDepartureLoader,
                  length: this.round_counter,
                  round: this.round_token,
                });
              }
              const bagItems = priceDetailsRes.price_details.items;
              this.users_details[i].roundway_bagList_arrival = [];
              for (const key in bagItems) {
                if (bagItems.hasOwnProperty(key)) {
                  const element = bagItems[key];
                  this.users_details[i].roundway_bagList_arrival.push({
                    bag_type: key,
                    price: element,
                  });
                }
              }
              this.users_details[i].roundway_arrival_bag_gst =
                priceDetailsRes.price_details.gst_price;
              this.users_details[i].roundway_arrival_bag_amount = amount;
              localStorage.setItem(
                "users_details",
                JSON.stringify(this.users_details)
              );
              localStorage.setItem("calculatedAmount", this.calculatedAmount);
            },
            (err) => {
              this.round_counter = this.round_counter + 1;
              this.totalAmountChanged.emit({
                amount: this.calculatedAmount,
                userDetails: this.users_details,
                departureLoader: this.departureLoader,
                arrivalLoader: this.arrivalLoader,
                roundArrivalLoader: this.roundArrivalLoader,
                roundDepartureLoader: this.roundDepartureLoader,
                length: this.round_counter,
                round: this.round_token,
              });
              console.log("err", err);
            }
          );
      }
      if (i === this.users_details.length - 1) {
        this.lengthLoader = true;
        this.totalAmountChanged.emit({
          amount: this.calculatedAmount,
          userDetails: this.users_details,
          departureLoader: this.departureLoader,
          arrivalLoader: this.arrivalLoader,
          roundArrivalLoader: this.roundArrivalLoader,
          roundDepartureLoader: this.roundDepartureLoader,
          length: this.round_counter,
          round: this.round_token,
        });
      }
    }
  }

  updatedJson: any;
  //razor pay integration
  proceedPay() {
    this.order_array = [];
    localStorage.removeItem("OrderDetails_V");
    let totalAmount = localStorage.getItem("calculatedAmount");
    this.updatedJson = JSON.parse(
      localStorage.getItem("users_details") || "[]"
    );

    let options = {
      key: environment.razorPayKey,
      amount: Math.round(Number(this.totalAmount_V)) * 100,
      currency: "INR",
      name: "CarterPorter",
      description: "Payment towards Carter",
      image: "https://cdn.razorpay.com/logos/Du4P7LfElD9azm_medium.jpg",

      handler: (response) => {
        this.ngZone.run(() => this.createBooking(0));
      },
      prefill: {
        name: this.updatedJson[0].name,
        email: this.updatedJson[0].email,
        contact: this.updatedJson[0].mobile,
      },
      notes: {
        address: "note value",
      },
      theme: {
        color: "#F37254",
      },
    };
    const rzp1 = new Razorpay(options);
    rzp1.open();
  }

  order_array = [];
  createBooking(counter) {
    let departure_counter = 0;
    let roundway_departure_counter = 0;
    let arrival_counter = 0;
    let roundway_arrival_counter = 0;
    let count = counter;
    document.getElementById("loader").style.display = "block";
    if (count < this.updatedJson.length) {
      if (
        this.departureCityA_id != "" &&
        this.updatedJson[count].selected_departure == true
      ) {
        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.departureAirportA_id,
          corporate_type: 4,
          city_name: this.departureCityA_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.users_details[count].departure_bag.bag,
          email: this.updatedJson[count].email,
          pincode: this.departureCityA_pincode,
          items_order: this.updatedJson[count].bagList_departure,
          gst_amount: this.updatedJson[count].departure_bag_gst,
          luggage_price: this.updatedJson[count].departure_bag_amount,
          // priceDetails.price_details.price_with_gst
          total_luggage_price: this.calculatedAmount,
          outstation_charge: 0,
          excess_bag_amount: 0,
          service_type: 2, // 1 for arrival, //2 for departure
          pickup_slot: 1,
          travell_passenger_name: this.updatedJson[count].name,
          travell_passenger_contact:
            this.updatedJson[count].mobile.length > 10
              ? this.updatedJson[count].mobile.slice(-10)
              : this.updatedJson[count].mobile,
          pick_drop_spots_type: 1,
          building_restriction: {
            "0": 5,
          },
          order_date: this.datePipe.transform(
            new Date(this.departureDateA),
            "yyyy/MM/dd"
          ),
          country_code: "91",
          flight_number: localStorage.getItem("pnrValue"),
        };

        this.service
          .createBooking(
            reqBody,
            this.updatedJson[count].departure_bag.departureToken
          )
          .pipe(throttleTime(250))
          .subscribe((data: any) => {
            if (data.status) {
              this.order = JSON.parse(data._body);
              this.order_array.push({
                orderId: this.order["order_number"],
                serviceType: 2,
                airportId: this.departureAirportA_id,
              });
              this.departure_info.push({
                OrderId: JSON.stringify(this.order_array),
              });
              localStorage.setItem(
                "OrderDetails_V",
                JSON.stringify(this.order_array)
              );
            }
          });
      }

      if (
        this.arrivalCityA_id != "" &&
        this.updatedJson[count].selected_arrival == true
      ) {
        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.arrivalAirportA_id,
          corporate_type: 4,
          city_name: this.arrivalCityA_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.updatedJson[count].arrival_bag.bag,
          email: this.updatedJson[count].email,
          pincode: this.arrivalCityA_pincode,
          items_order: this.updatedJson[count].bagList_arrival,
          gst_amount: this.updatedJson[count].arrival_bag_gst,
          luggage_price: this.updatedJson[count].arrival_bag_amount,
          // priceDetails.price_details.price_with_gst
          total_luggage_price: this.calculatedAmount,
          outstation_charge: 0,
          excess_bag_amount: 0,
          service_type: 1, // 1 for arrival, //2 for departure
          pickup_slot: 1,
          travell_passenger_name: this.updatedJson[count].name,
          travell_passenger_contact:
            this.updatedJson[count].mobile.length > 10
              ? this.updatedJson[count].mobile.slice(-10)
              : this.updatedJson[count].mobile,
          pick_drop_spots_type: 1,
          building_restriction: {
            "0": 5,
          },
          order_date: this.datePipe.transform(
            new Date(this.arrivalDateA),
            "yyyy/MM/dd"
          ),
          country_code: "91",
          flight_number: localStorage.getItem("pnrValue"),
        };

        this.service
          .createBooking(
            reqBody,
            this.updatedJson[count].arrival_bag.arrivalToken
          )
          .pipe(throttleTime(250))
          .subscribe((data: any) => {
            if (data.status) {
              this.order = JSON.parse(data._body);
              this.order_array.push({
                orderId: this.order["order_number"],
                serviceType: 1,
                airportId: this.arrivalAirportA_id,
              });
              this.departure_info.push({
                OrderId: JSON.stringify(this.order_array),
              });
              localStorage.setItem(
                "OrderDetails_V",
                JSON.stringify(this.order_array)
              );
            }
          });
      }

      if (
        this.departureCityD_id != "" &&
        this.updatedJson[count].selected_round_departure == true
      ) {
        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.departureAirportD_id,
          corporate_type: 4,
          city_name: this.departureCityD_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.updatedJson[count].roundway_departure_bag.bag,
          email: this.updatedJson[count].email,
          pincode: this.departureCityD_pincode,
          items_order: this.updatedJson[count].roundway_bagList_departure,
          gst_amount: this.updatedJson[count].roundway_departure_bag_gst,
          luggage_price: this.updatedJson[count].departure_bag_amount,
          // priceDetails.price_details.price_with_gst
          total_luggage_price: this.calculatedAmount,
          outstation_charge: 0,
          excess_bag_amount: 0,
          service_type: 2, // 1 for arrival, //2 for departure
          pickup_slot: 1,
          travell_passenger_name: this.updatedJson[count].name,
          travell_passenger_contact:
            this.updatedJson[count].mobile.length > 10
              ? this.updatedJson[count].mobile.slice(-10)
              : this.updatedJson[count].mobile,
          pick_drop_spots_type: 1,
          building_restriction: {
            "0": 5,
          },
          order_date: this.datePipe.transform(
            new Date(this.departureDateD),
            "yyyy/MM/dd"
          ),
          country_code: "91",
          flight_number: localStorage.getItem("pnrValue"),
        };

        this.service
          .createBooking(
            reqBody,
            this.updatedJson[count].roundway_departure_bag.departureToken
          )
          .pipe(throttleTime(250))
          .subscribe((data: any) => {
            if (data.status) {
              this.order = JSON.parse(data._body);
              this.order_array.push({
                orderId: this.order["order_number"],
                serviceType: 2,
                airportId: this.departureAirportD_id,
              });
              this.departure_info.push({
                OrderId: JSON.stringify(this.order_array),
              });
              localStorage.setItem(
                "OrderDetails_V",
                JSON.stringify(this.order_array)
              );
            }
          });
      }

      if (
        this.arrivalCityD_id != "" &&
        this.updatedJson[count].selected_round_arrival == true
      ) {
        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.arrivalAirportD_id,
          corporate_type: 4,
          city_name: this.arrivalCityD_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.updatedJson[count].roundway_arrival_bag.bag,
          email: this.updatedJson[count].email,
          pincode: this.arrivalCityD_pincode,
          items_order: this.updatedJson[count].roundway_bagList_arrival,
          gst_amount: this.updatedJson[count].roundway_arrival_bag_gst,
          luggage_price: this.updatedJson[count].arrival_bag_amount,
          // priceDetails.price_details.price_with_gst
          total_luggage_price: this.calculatedAmount,
          outstation_charge: 0,
          excess_bag_amount: 0,
          service_type: 1, // 1 for arrival, //2 for departure
          pickup_slot: 1,
          travell_passenger_name: this.updatedJson[count].name,
          travell_passenger_contact:
            this.updatedJson[count].mobile.length > 10
              ? this.updatedJson[count].mobile.slice(-10)
              : this.updatedJson[count].mobile,
          pick_drop_spots_type: 1,
          building_restriction: {
            "0": 5,
          },
          order_date: this.datePipe.transform(
            new Date(this.arrivalDateD),
            "yyyy/MM/dd"
          ),
          country_code: "91",
          flight_number: localStorage.getItem("pnrValue"),
        };

        this.service
          .createBooking(
            reqBody,
            this.updatedJson[count].roundway_arrival_bag.arrivalToken
          )
          .pipe(throttleTime(250))
          .subscribe((data: any) => {
            if (data.status) {
              this.order = JSON.parse(data._body);
              this.order_array.push({
                orderId: this.order["order_number"],
                serviceType: 1,
                airportId: this.arrivalAirportD_id,
              });
              this.departure_info.push({
                OrderId: JSON.stringify(this.order_array),
              });
              localStorage.setItem(
                "OrderDetails_V",
                JSON.stringify(this.order_array)
              );
            }
          });
      }
    }

    if (this.departureCityAirpotDetails != "") {
      localStorage.setItem("orderDetails", this.departure_info);
    } else {
      localStorage.setItem("departure", this.arrival_info);
    }
    setTimeout(
      () => {
        count < this.updatedJson.length
          ? this.createBooking(count + 1)
          : ((document.getElementById("loader").style.display = "none"),
            this.router.navigate(["order-confirmation-page"]));
      },
      this.updatedJson.length === 1 ? 10000 : 7000
    );
  }

  uncheckAll(id) {
    // if (this.checkAll == 'checked') {
    //   this.checkAll = 'disabled';
    // } else {
    //   this.checkAll = 'checked'
    // }
  }

  uncheck(position) {}

  //toggle
  loader: boolean = false;
  setToggle(e, type, data, index) {
    this.selectedBookingData = [];
    if (e.target.checked === false) {
      this.users_details.forEach((user) => {
        if (user["passengerID"] == data.PassengerID && type == "departure")
          user.selected_departure = false;
        else if (user["passengerID"] === data.PassengerID && type === "arrival")
          user.selected_arrival = false;
        else if (
          user["passengerID"] === data.PassengerID &&
          type === "arrival round"
        )
          user.selected_round_arrival = false;
        else if (
          user["passengerID"] === data.PassengerID &&
          type === "departure round"
        )
          user.selected_round_departure = false;
      });
      $(".dwdr" + index).prop("checked", false);
      // if (this.round_counter !== this.round_token) {
      //   e.preventDefault();
      // }
    } else if (e.target.checked === true) {
      this.users_details.forEach((user) => {
        if (user["passengerID"] == data.PassengerID && type == "departure")
          user.selected_departure = true;
        else if (user["passengerID"] == data.PassengerID && type == "arrival")
          user.selected_arrival = true;
        else if (
          user["passengerID"] === data.PassengerID &&
          type === "arrival round"
        )
          user.selected_round_arrival = true;
        else if (
          user["passengerID"] === data.PassengerID &&
          type === "departure round"
        )
          user.selected_round_departure = true;
      });
      // if ($(".dwd"+index).prop('checked', true) &&
      // $(".dwd"+index).prop('checked', true) &&
      // $(".dwd"+index).prop('checked', true) &&
      // $(".dwd"+index).prop('checked', true)) {
      //   $(".dwdr" + index).prop('checked', true);
      // }
    }
    this.calculatingAmout();
  }

  setToggling(e, data, index) {
    this.selectedBookingData = [];
    // this.users_details[index]
    if (e.target.checked === false) {
      this.users_details.forEach((user) => {
        if (user["passengerID"] == data.PassengerID) {
          user.selected_departure = false;
          user.selected_arrival = false;

          user.selected_round_arrival = false;

          user.selected_round_departure = false;
          $(".dwd" + index).prop("checked", false);
          $(".dwd" + index).prop("checked", false);
          $(".dwd" + index).prop("checked", false);
          $(".dwd" + index).prop("checked", false);
        }
      });
    } else if (e.target.checked === true) {
      this.users_details.forEach((user) => {
        if (user["passengerID"] == data.PassengerID) {
          user.selected_departure = true;
          user.selected_arrival = true;

          user.selected_round_arrival = true;

          user.selected_round_departure = true;
          $(".dwd" + index).prop("checked", true);
          $(".dwd" + index).prop("checked", true);
          $(".dwd" + index).prop("checked", true);
          $(".dwd" + index).prop("checked", true);
        }
      });
    }
    this.calculatingAmout();
  }

  selectedDataHandler(item, type, airportId, price, position, index) {
    this.selectedBookingData.push({
      id: item.passengerID,
      name: item.name,
      type: type,
      airportId: airportId,
      price: price,
      position: position,
      index: index,
    });
    if ($(".dwd:checked").length == this.selectedBookingData.length) {
      this.calculateTotalAmount(this.selectedBookingData);
      console.log(
        "dwd",
        $(".dwd:checked").length,
        "selectedBookingData",
        this.selectedBookingData.length
      );
    }
  }

  calculateTotalAmount(data) {
    this.totalAmount_V = 0;
    let PassengersData = null;
    let filterData = null;
    filterData = data.map((v, i, a) => a.filter((t) => t.id === v.id));
    PassengersData = _.unionWith(filterData, _.isEqual);
    console.log("PassengersData", PassengersData);

    for (const value of PassengersData) {
      if (value.length > 1) {
        if (value.length == 3) {
          if (
            (value[0].position == "leftTop" &&
              value[1].position == "rightTop") ||
            (value[1].position == "leftTop" && value[0].position == "rightTop")
          ) {
            this.totalAmount_V =
              this.totalAmount_V +
              value[0].price +
              value[1].price +
              value[2].price -
              ((value[0].price + value[1].price) * 1) / 100;
          }
          if (
            (value[0].position == "leftBottom" &&
              value[1].position == "rightBottom") ||
            (value[1].position == "leftBottom" &&
              value[0].position == "rightBottom")
          ) {
            this.totalAmount_V =
              this.totalAmount_V +
              value[0].price +
              value[1].price +
              value[2].price -
              ((value[0].price + value[1].price) * 1) / 100;
          }
          if (
            (value[0].position == "leftTop" &&
              value[2].position == "rightTop") ||
            (value[2].position == "leftTop" && value[0].position == "rightTop")
          ) {
            this.totalAmount_V =
              this.totalAmount_V +
              value[0].price +
              value[1].price +
              value[2].price -
              ((value[0].price + value[2].price) * 1) / 100;
          }
          if (
            (value[0].position == "leftBottom" &&
              value[2].position == "rightBottom") ||
            (value[2].position == "leftBottom" &&
              value[0].position == "rightBottom")
          ) {
            this.totalAmount_V =
              this.totalAmount_V +
              value[0].price +
              value[1].price +
              value[2].price -
              ((value[0].price + value[2].price) * 1) / 100;
          }
        } else if (value.length == 4) {
          this.totalAmount_V =
            this.totalAmount_V +
            value[0].price +
            value[1].price +
            value[2].price +
            value[3].price -
            ((value[0].price +
              value[1].price +
              value[2].price +
              value[3].price) *
              2) /
              100;
        } else {
          if (
            (value[0].type == "arrival" && value[1].type == "departure") ||
            (value[0].type == "departure" && value[1].type == "arrival")
          ) {
            this.totalAmount_V =
              this.totalAmount_V +
              value[0].price +
              value[1].price -
              ((value[0].price + value[1].price) * 1) / 100;
          } else {
            this.totalAmount_V =
              this.totalAmount_V + value[0].price + value[1].price;
          }
        }
      } else {
        this.totalAmount_V = this.totalAmount_V + value[0].price;
      }
    }
    this.AmountOutput.emit(this.totalAmount_V);
    console.log("totalAmount_Round", this.totalAmount_V);
  }
}
