import { Injectable } from "@angular/core";
import {
  Http,
  Response,
  Headers,
  RequestOptions,
  URLSearchParams,
} from "@angular/http";
import { map, catchError } from "rxjs/operators";
import {
  NgxSoapService,
  ISoapMethod,
  Client,
  ISoapMethodResponse,
} from "ngx-soap";
import { environment, apis } from "../environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SpicejetService {
  options: RequestOptions;

  xmlFile: Client;
  passengerBooking: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  pbObs = this.passengerBooking.asObservable();
  data: any;
  departureTokenKey: any;
  arrivalTokenKey: any;

  constructor(private http: Http, private soap: NgxSoapService) {}

  getPassengerBooking(data) {
    this.passengerBooking.next(data);
  }

  //for login and get signature
  getLogin() {
    //set the header
    const httpOptions = {
      headers: new Headers({
        Authorization: "bearer WORKINEAGEL#123ALPHASIN",
        "Response-Type": "application/json",
      }),
    };
    //callling post request
    return this.http.post(environment.apiUrl + apis.login, "", httpOptions);
  }

  //to fetch booking details
  getBookingDetails(signature, pnr, email) {
    //set the body
    const body = { pnr: pnr, email_or_last_name: email };
    //set the header
    const httpOptions = {
      headers: new Headers({
        Authorization: "bearer WORKINEAGEL#123ALPHASIN",
        signature: signature,
      }),
    };

    //callling get request
    return this.http.post(
      environment.apiUrl + apis.fetchBooking,
      body,
      httpOptions
    );
  }

  //to get Amount
  getBaggaeAmount(reqBody, token) {
    // set header
    const httpOptions = {
      headers: new Headers({
        "Content-Type": "application/json",
        // token: serviceType === 2 ? '32855d9d3193f985697f289a0e7cb657' : '9d73076203afeeb232aacadc7c8c8c33'
        token: token,
      }),
    };

    return this.http.post(
      "https://carter-cors.herokuapp.com/" +
        environment.carterXUrl +
        apis.getAmount,
      reqBody,
      httpOptions
    );
  }

  //booking Api
  createBooking(data, token) {
    const httpOptions = {
      headers: new Headers({
        "Content-Type": "application/json",
        token: token,
      }),
    };
    return this.http.post(
      "https://carter-cors.herokuapp.com/" +
        environment.carterXUrl +
        apis.booking,
      data,
      httpOptions
    );
  }
}
