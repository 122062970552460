import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CrudService {
  loggedUser = false;

  constructor(private http: HttpClient) {}

  public post(api, postdata) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        // 'Authorization': 'my-auth-token'
      }),
    };
    return this.http.post(environment.carterXUrl + api, postdata, httpOptions);
  }

  public get(api) {
    return this.http.get(environment.carterXUrl + api);
  }

  public update(api, data) {
    return this.http.post(environment.carterXUrl + api, data);
  }

  public delete(api, data) {
    return this.http.delete(environment.carterXUrl + api, data);
  }

  public getAllCityList(api) {
    return this.http.get(api);
  }

  public postJson(api, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=UTF-8",
        // 'Authorization': 'my-auth-token'
      }),
    };
    return this.http.post(
      "https://carter-cors.herokuapp.com/" + environment.carterXUrl + api,
      data,
      httpOptions
    );
  }

  public postFormdata(api, postdata) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "text/html; charset=UTF-8",
        // 'Authorization': 'my-auth-token'
      }),
    };
    return this.http.post(environment.carterXUrl + api, postdata, httpOptions);
  }

  public postFormdataUploadImage(api, data) {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'multipart/form-data',
    //   })
    // };
    return this.http.post(environment.carterXUrl + api, data);
  }

  public postOla(api, data, token) {
    console.log("here");
    console.log("token", token);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token,
      }),
    };
    return this.http.post(
      "https://carter-cors.herokuapp.com/" + environment.carterXUrl + api,
      data,
      httpOptions
    );
  }

  public postOlaBooking(api, data, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token,
      }),
    };
    return this.http.post(
      "https://carter-cors.herokuapp.com/" + environment.carterXUrl + api,
      data,
      httpOptions
    );
  }

  //////////intertransfer
  public postWithDytnamicToken(api, data, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token,
      }),
    };
    return this.http.post(
      "https://carter-cors.herokuapp.com/" + environment.carterXUrl + api,
      data,
      httpOptions
    );
  }
  public postWithCorporateToken(api, data, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token,
      }),
    };
    return this.http.post(
      "https://carter-cors.herokuapp.com/" + environment.carterXUrl + api,
      data,
      httpOptions
    );
  }
}
