import { analyzeAndValidateNgModules } from "@angular/compiler";
import { Component, Input, OnInit } from "@angular/core";
import { ViewChild, Output, EventEmitter } from "@angular/core";

import { SpicejetService } from "../spicejet.service";
import { OneWayComponent } from "../one-way/one-way.component";
import { RoundWayComponent } from "../round-way/round-way.component";
import { ConnectingFlightComponent } from "../connecting-flight/connecting-flight.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-booking-details",
  templateUrl: "./booking-details.component.html",
  styleUrls: ["./booking-details.component.scss"],
})
export class BookingDetailsComponent implements OnInit {
  @ViewChild(OneWayComponent, { static: false }) oneWayChild: OneWayComponent;

  @ViewChild(ConnectingFlightComponent, { static: false })
  connectingFlightChild: ConnectingFlightComponent;

  @ViewChild(RoundWayComponent, { static: false })
  roundWayChild: RoundWayComponent;

  totalAmountCount: any = 0;

  checked: boolean = false;
  toggle: Boolean = false;
  pnr: any;
  email: any;
  loader: boolean = false;
  connectingFlight: any;

  constructor(
    private service: SpicejetService,
    private toastr: ToastrService
  ) {}

  bookingDetails: any;
  sig_json: any;
  name: any;
  trip: any;
  journeyDetails: any;
  mobNum: any;

  ngOnInit() {
    this.getBookingDetails();
    this.service.pbObs.subscribe((res) => {});
  }

  isConnecting: boolean = false;
  //get the signature
  getBookingDetails() {
    this.pnr = localStorage.getItem("pnrValue");
    this.email = localStorage.getItem("emailValue");
    this.loader = true;
    this.service
      .getBookingDetails(
        localStorage.getItem("signature"),
        localStorage.getItem("pnrValue"),
        localStorage.getItem("emailValue")
      )
      .subscribe(
        (res) => {
          this.loader = false;
          this.sig_json = JSON.parse(res["_body"]);
          this.trip = this.sig_json.trip;
          this.name =
            this.sig_json.bookingContact.Names.BookingName.FirstName +
            " " +
            this.sig_json.bookingContact.Names.BookingName.LastName;
          this.journeyDetails = this.sig_json.journeyDetailsData;
          this.connectingFlight = this.journeyDetails.Segments;
          this.isConnecting = Array.isArray(this.connectingFlight.Segment);
        },
        (err) => {
          this.loader = false;
        }
      );
  }

  toggleSwitch() {
    this.toggle = !this.toggle;
  }
  buttonLoader: any = false;
  totalAmount: any = 0;
  totalAmount_V: any = 0;
  count_value: any = 0;
  getApproxAmount(value) {
    if (value.round === 0) {
      this.totalAmountCount = 0;
    }
    this.count_value = value.round;
    this.buttonLoader = true;

    // if (value.amount !== this.totalAmount && value.round !==0) {
    //   this.totalAmountCount = this.totalAmountCount +1;
    // }
    this.totalAmount = Number(value.amount);
    if (value.userDetails.length > 0) {
      if (this.trip == "One Way") {
        if (value.arrivalLoader == false && value.departureLoader == false) {
          this.buttonLoader = false;
        }
      }
      if (this.trip == "Round Trip") {
        if (value.length === value.round) {
          this.buttonLoader = false;
        } else if (value.length !== value.round) {
          this.buttonLoader = true;
        }
      }
      if (
        this.trip == "One Way" &&
        this.connectingFlight.Segment[0].ArrivalStation ==
          this.connectingFlight.Segment[1].DepartureStation
      ) {
        if (value.arrivalLoader == false && value.departureLoader == false) {
          this.buttonLoader = false;
        }
      }
    } else {
      this.buttonLoader = false;
    }
    // this.enable = true;
    this.checked = false;
  }

  enable: any = true;
  terms() {
    this.checked = !this.checked;
    if (this.checked === true && this.totalAmount > 0) this.enable = false;
    else this.enable = true;
  }

  //calling child component function
  callChildBooking() {
    if (this.trip == "One Way") {
      if (this.handleValidation()) {
        this.oneWayChild.proceedPay();
      }
    } else if (this.trip == "Round Trip") {
      if (this.handleValidation()) {
        this.roundWayChild.proceedPay();
      }
    }
  }

  handleValidation() {
    var formIsValid = true;
    this.sig_json.bookingContact.HomePhone = this.sig_json.bookingContact.HomePhone.slice(
      -10
    );
    if (!this.sig_json.bookingContact.HomePhone) {
      this.toastr.error("Mobile Cannot be empty");
      formIsValid = false;
    }
    // eslint-disable-next-line
    if (
      this.sig_json.bookingContact.HomePhone.length < 10 ||
      this.sig_json.bookingContact.HomePhone.length > 10
    ) {
      this.toastr.error("Invalid Mobile Number");
      formIsValid = false;
    }
    return formIsValid;
  }

  callChildBookingConnecting() {
    if (this.handleValidation()) {
      this.connectingFlightChild.proceedPay();
    }
  }

  button_change(event) {}

  getAmountHandler(amount: any) {
    console.log("Total Amount Booking", amount);
    this.totalAmount_V = amount;
  }
}
