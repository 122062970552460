import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
// import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CrudService } from "../crud.service";
import { HttpClient } from "@angular/common/http";
import _ from "lodash";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-booking-confirmation",
  templateUrl: "./booking-confirmation.component.html",
  styleUrls: ["./booking-confirmation.component.scss"],
})
export class BookingConfirmationComponent implements OnInit {
  tab_type: any;
  OrderId: any;
  serviceType: any;
  showRoundTrip: any;
  roundTripTwoOrderId: any = [];
  orders: any;
  singleAirport: any = [];
  secondAirport: any = [];
  loader: boolean = false;
  allCityList: any;
  airPortDetails: any = [];
  airportFullDetails: any = [];

  constructor(
    private router: Router,
    private crudService: CrudService,
    private http: HttpClient
  ) {
    this.orders = JSON.parse(localStorage.getItem("OrderDetails_V"));
    this.serviceType = parseInt(localStorage.getItem("serviceType"));
    this.showRoundTrip = parseInt(localStorage.getItem("showRoundTrip"));
    this.tab_type = localStorage.getItem("tabType");
  }

  ngOnInit() {
    this.airportFullDetails = [];
    localStorage.getItem("firstAirport");
    scrollTo(0, 0);
    this.getAllCityListHandler();
  }

  getAllCityListHandler() {
    this.crudService
      .getAllCityList(
        environment.carterXUrl+"r=v2/airport-of-operation-api/region-new"
      )
      .subscribe((res: any) => {
        this.allCityList = res.response.airport;
        this.airPortDetailsHandler();
      });
  }

  airPortDetailsHandler() {
    this.airportFullDetails = [];
    this.orders.map((order) => {
      var data = [];
      var airportDetails = _.find(this.allCityList, function (o) {
        return o.airport_name_id == order.airportId;
      });
      const airportDescShow =
        airportDetails &&
        airportDetails.description &&
        airportDetails.description.split("&");
      airportDescShow &&
        airportDescShow.forEach((airport) => {
          const xyz = airport.split(":");
          data.push(xyz);
        });
      this.airportFullDetails.push(data);
    });
  }

  downloadPdf(oID) {
    this.crudService
      .get("r=order/orderpdf&order_number=" + oID)
      .subscribe((res: any) => {
        if (res.status) {
          window.open(res.path, "_blank");
        }
      });
  }
}
