import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit {

  navbarItem = [
    {
      icon: '../../assets/icons/faq/Registration.png',
      name: 'About the Service'
    },
    {
      icon: '../../assets/icons/faq/Order Related.png',
      name: 'Order Booking Related'
    },
    {
      icon: '../../assets/icons/faq/Order Related.png',
      name: 'Post Order Confirmation:'
    },
    {
      icon: '../../assets/icons/faq/Pickup and Delivery Related.png',
      name: 'Pickup/Delivery/Non Delivery Related'
    },
    {
      icon: '../../assets/icons/faq/Payment.png',
      name: 'Payment Related'
    },
    {
      icon: '../../assets/icons/faq/Pickup and Delivery Related.png',
      name: 'Luggage Related'
    },
    {
      icon: '../../assets/icons/faq/Insurance Related.png',
      name: 'Insurance Related'
    },
    {
      icon: '../../assets/icons/faq/Customer Related.png',
      name: 'Cancel & Refund Policy'
    },
    {
      icon: '../../assets/icons/faq/Customer Related.png',
      name: 'Contact Us and Other FAQs'
    },

  ];

  selected_Nav_Item: string = "About the Service";
  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);
  }

  setActive(nav_Item) {
    this.selected_Nav_Item = nav_Item;
  }

}
