import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  NgZone,
} from "@angular/core";
import { SpicejetService } from "../spicejet.service";
import { throttleTime } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { CrudService } from "../crud.service";
import { apis, CORPORATE_APIS } from "../../app/config/apis";
import { UtilService } from "../util.service";
import {
  bagArray,
  razorPayKey,
  workingCityArry,
  environment,
} from "../../environments/environment";
import _ from "lodash";

//Razorpay get way
declare var Razorpay: any;
declare var $: any;

@Component({
  selector: "app-one-way",
  templateUrl: "./one-way.component.html",
  styleUrls: ["./one-way.component.scss"],
})
export class OneWayComponent implements OnInit {
  loading: boolean = true;
  order: any;
  bagdep: any;
  bagar: any;

  test: boolean = false;
  type_of_services: any;
  arrival_token: any;
  departure_token: any;

  HYD: any;
  BOM: any;
  DEL: any;
  BLR: any;
  @Input() totalAmount: number;

  @Output() totalAmountChanged: EventEmitter<{
    amount: number;
    userDetails: [];
    arrivalLoader: boolean;
    departureLoader: boolean;
  }> = new EventEmitter();

  bagItemListLeft: any = 1;
  bagItemListRight: any = 1;

  workingCityArry = workingCityArry;

  bookingDetails: any;
  sig_json: any;
  name: any;
  // trip: any;
  journeyDetails: any;
  arrivalDate: any;
  departureDate: any;
  passengerList: any;
  passengerListItemData: any[] = [];
  legArrivalDate: any;
  legDepartureDate: any;
  flightDetail: any;

  departureCity_id: any = "";
  departureAirport_id: any;
  departureCity_pincode: any;

  arrivalCity_id: any = "";
  arrivalAirport_id: any;
  arrivalCity_pincode: any;

  users_details: any = [];
  bagArr = bagArray;

  departureCityAirpotDetails: any = "";
  arrivalCityAirpotDetails: any = "";
  selectedBookingData: any = [];
  totalAmount_V: number = 0;

  @Output() AmountOutput: EventEmitter<{}> = new EventEmitter();

  constructor(
    private service: SpicejetService,
    private datePipe: DatePipe,
    private util: UtilService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  ngOnInit() {
    this.util
      .lazyLoadLibrary("https://checkout.razorpay.com/v1/checkout.js")
      .subscribe();
    this.service
      .getBookingDetails(
        localStorage.getItem("signature"),
        localStorage.getItem("pnrValue"),
        localStorage.getItem("emailValue")
      )
      .subscribe((res) => {
        this.loading = false;
        this.sig_json = JSON.parse(res["_body"]);
        this.journeyDetails = this.sig_json.journeyDetailsData.Segments.Segment;

        this.arrivalDate = this.journeyDetails.STA;
        this.departureDate = this.journeyDetails.STD;
        this.passengerList = this.sig_json["passengerData"];
        this.legArrivalDate = this.journeyDetails.Legs.Leg.STA;
        this.legDepartureDate = this.journeyDetails.Legs.Leg.STD;
        this.flightDetail = this.sig_json.FlightDesignator;

        let bagData = [1, 2, 3, 4, 5, 6, 7, 8];

        if (this.passengerList.length > 0) {
          this.passengerList.map((element) => {
            element.bagItemListLeft = 1;
            element.bagItemListRight = 1;
            this.passengerListItemData.push(element);
          });
        } else {
          this.passengerList.bagItemListLeft = 1;
          this.passengerList.bagItemListRight = 1;
          this.passengerListItemData.push(this.passengerList);
        }
        this.passengerListItemData.map((data) => {
          data.bagDataLeft = [];
          data.bagDataLeft = bagData;
          data.bagDataRight = [];
          data.bagDataRight = JSON.parse(JSON.stringify(bagData));
        });

        this.passengerList = this.passengerListItemData;

        //fetch departure and arrival, city id and airport id and pincode
        for (let i = 0; i < this.workingCityArry.length; i++) {
          if (
            this.journeyDetails.DepartureStation ==
            this.workingCityArry[i].city_code
          ) {
            this.departureCity_id = this.workingCityArry[i].city_id;
            this.departureCity_pincode = this.workingCityArry[i].pincode;
            this.departureAirport_id = this.workingCityArry[i].airport_id;
            this.departureCityAirpotDetails = this.workingCityArry[i].details;
          }

          if (
            this.journeyDetails.ArrivalStation ==
            this.workingCityArry[i].city_code
          ) {
            this.arrivalCity_id = this.workingCityArry[i].city_id;
            this.arrivalCity_pincode = this.workingCityArry[i].pincode;
            this.arrivalAirport_id = this.workingCityArry[i].airport_id;
            this.arrivalCityAirpotDetails = this.workingCityArry[i].details;
          }
        }

        // creating user json
        this.passengerListItemData.map((items) => {
          this.users_details.push({
            name:
              items.Names.BookingName.FirstName +
              " " +
              items.Names.BookingName.LastName,
            mobile: this.sig_json.bookingContact.HomePhone,
            departure_bag: this.bagArr[0],
            arrival_bag: this.bagArr[0],
            email: this.sig_json.bookingContact.EmailAddress,
            departure_bag_amount: 0,
            arrival_bag_amount: 0,
            arrival_city: this.arrivalCity_id,
            departure_city: this.departureCity_id,
            arrival_pincode: this.arrivalCity_pincode,
            departure_pincode: this.departureCity_pincode,
            arrival_airport: this.arrivalAirport_id,
            departure_airport: this.departureAirport_id,
            bagList_departure: [],
            bagList_arrival: [],
            departure_bag_gst: "",
            arrival_bag_gst: "",
            passengerID: items.PassengerID,
            selected_departure: this.departureCity_id !== "" ? true : false,
            selected_arrival: this.arrivalCity_id !== "" ? true : false,
          });
        });

        this.calculatingAmout();
      });

    // this.service.pbObs.subscribe(res =>{
    // })
  }

  //bags
  selected(bagData, position, item) {
    if (position === "left") {
      for (let i = 0; i < this.users_details.length; i++) {
        if (item.PassengerID == this.users_details[i].passengerID) {
          this.users_details[i]["departure_bag"] = bagData;
        }
      }
      item.bagItemListLeft = bagData.bag;
      this.bagdep = item.bagItemListLeft;
    } else if (position === "right") {
      for (let i = 0; i < this.users_details.length; i++) {
        if (item.PassengerID == this.users_details[i].passengerID) {
          this.users_details[i].arrival_bag = bagData;
        }
      }
      item.bagItemListRight = bagData.bag;
      this.bagar = item.bagItemListLeft;
    }
    this.calculatingAmout();
  }

  approxAmountCounter: any = 0;
  calculatedAmount: any = 0;
  priceDetailsRes: any;
  finaljson: any;
  // calculating departure and arrival amount

  arrivalLoader: any = false;
  departureLoader: any = false;
  counter: any = 0;
  calculatingAmout() {
    this.calculatedAmount = 0;
    this.totalAmountChanged.emit({
      amount: this.calculatedAmount,
      userDetails: this.users_details,
      departureLoader: this.departureLoader,
      arrivalLoader: this.arrivalLoader,
    });
    for (let i = 0; i < this.users_details.length; i++) {
      // for departure
      if (
        this.departureCity_id != "" &&
        this.users_details[i].selected_departure == true
      ) {
        // set body
        this.departureLoader = true;
        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.departureAirport_id,
          city_name: this.departureCity_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.users_details[i].departure_bag.bag,
          pincode: this.departureCity_pincode,
          service_type: 2, //departure:2 arrival:1
        };
        this.service
          .getBaggaeAmount(
            reqBody,
            this.users_details[i].departure_bag.departureToken
          )
          .subscribe(
            (res) => {
              this.departureLoader = false;
              const itemsOrder = [];
              let priceDetailsRes;
              let a = JSON.parse(res["_body"]);
              priceDetailsRes = JSON.parse(res["_body"]);
              let amount =
                a.price_details.price_with_gst > 0
                  ? a.price_details.price_with_gst
                  : 0;
              this.selectedDataHandler(
                this.users_details[i],
                "departure",
                this.departureAirport_id,
                amount,
                "left",
                i
              );
              this.calculatedAmount = amount + this.calculatedAmount;
              this.totalAmountChanged.emit({
                amount: this.calculatedAmount,
                userDetails: this.users_details,
                departureLoader: this.departureLoader,
                arrivalLoader: this.arrivalLoader,
              });
              const bagItems = priceDetailsRes.price_details.items;
              this.users_details[i].bagList_departure = [];
              for (const key in bagItems) {
                if (bagItems.hasOwnProperty(key)) {
                  const element = bagItems[key];
                  this.users_details[i].bagList_departure.push({
                    bag_type: key,
                    price: element,
                  });
                }
              }
              this.users_details[i].departure_bag_gst =
                priceDetailsRes.price_details.gst_price;
              this.users_details[i].departure_bag_amount = amount;

              localStorage.setItem(
                "users_details",
                JSON.stringify(this.users_details)
              );
              localStorage.setItem("calculatedAmount", this.calculatedAmount);
            },
            (err) => {
              console.log("err", err);
            }
          );
      }

      // for arrival
      if (
        this.arrivalCity_id != "" &&
        this.users_details[i].selected_arrival == true
      ) {
        // set body
        this.arrivalLoader = true;
        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.arrivalAirport_id,
          city_name: this.arrivalCity_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.users_details[i].arrival_bag.bag,
          pincode: this.arrivalCity_pincode,
          service_type: 2, //departure:2 arrival:1
        };
        this.service
          .getBaggaeAmount(
            reqBody,
            this.users_details[i].arrival_bag.arrivalToken
          )
          .subscribe(
            (res) => {
              this.arrivalLoader = false;
              const itemsOrder = [];
              let priceDetailsRes;
              let a = JSON.parse(res["_body"]);
              priceDetailsRes = JSON.parse(res["_body"]);
              //
              let amount =
                a.price_details.price_with_gst > 0
                  ? a.price_details.price_with_gst
                  : 0;
              this.selectedDataHandler(
                this.users_details[i],
                "arrival",
                this.arrivalAirport_id,
                amount,
                "right",
                i
              );
              this.calculatedAmount = amount + this.calculatedAmount;
              this.totalAmountChanged.emit({
                amount: this.calculatedAmount,
                userDetails: this.users_details,
                departureLoader: this.departureLoader,
                arrivalLoader: this.arrivalLoader,
              });
              const bagItems = priceDetailsRes.price_details.items;
              this.users_details[i].bagList_arrival = [];
              for (const key in bagItems) {
                if (bagItems.hasOwnProperty(key)) {
                  const element = bagItems[key];
                  this.users_details[i].bagList_arrival.push({
                    bag_type: key,
                    price: element,
                  });
                }
              }
              this.users_details[i].arrival_bag_gst =
                priceDetailsRes.price_details.gst_price;
              this.users_details[i].arrival_bag_amount = amount;
              localStorage.setItem(
                "users_details",
                JSON.stringify(this.users_details)
              );
              localStorage.setItem("calculatedAmount", this.calculatedAmount);
            },
            (err) => {
              console.log("err", err);
            }
          );
      }
    }
  }

  updatedJson: any;
  //razor pay integration
  proceedPay() {
    this.order_array = [];
    localStorage.removeItem("OrderDetails_V");
    let totalAmount = localStorage.getItem("calculatedAmount");
    this.updatedJson = JSON.parse(
      localStorage.getItem("users_details") || "[]"
    );
    let options = {
      key: environment.razorPayKey,
      amount: Math.round(Number(this.totalAmount_V)) * 100,
      currency: "INR",
      name: "CarterPorter",
      description: "Payment towards Carter",
      image: "https://cdn.razorpay.com/logos/Du4P7LfElD9azm_medium.jpg",

      handler: (response) => {
        this.ngZone.run(() => this.createBooking(0));
      },
      prefill: {
        name: this.users_details[0].name,
        email: this.users_details[0].email,
        contact: this.users_details[0].mobile,
      },
      notes: {
        address: "note value",
      },
      theme: {
        color: "#F37254",
      },
    };
    const rzp1 = new Razorpay(options);
    rzp1.open();
  }

  order_array = [];
  isBooking: boolean = false;
  // creating order
  createBooking(counter) {
    let departure_counter = 0;
    let arrival_counter = 0;
    let count = counter;
    this.loading = true;
    document.getElementById("loader").style.display = "block";
    if (count < this.updatedJson.length) {
      if (
        this.departureCity_id != "" &&
        this.updatedJson[count].selected_departure == true
      ) {
        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.departureAirport_id,
          corporate_type: 4,
          city_name: this.departureCity_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.updatedJson[count].departure_bag.bag,
          email: this.updatedJson[count].email,
          pincode: this.departureCity_pincode,
          items_order: this.updatedJson[count].bagList_departure,
          gst_amount: this.updatedJson[count].departure_bag_gst,
          luggage_price: this.updatedJson[count].departure_bag_amount,
          // priceDetails.price_details.price_with_gst
          total_luggage_price: this.calculatedAmount,
          outstation_charge: 0,
          excess_bag_amount: 0,
          service_type: 2, // 1 for arrival, //2 for departure
          pickup_slot: 1,
          travell_passenger_name: this.updatedJson[count].name,
          travell_passenger_contact:
            this.updatedJson[count].mobile.length > 10
              ? this.updatedJson[count].mobile.slice(-10)
              : this.updatedJson[count].mobile,
          pick_drop_spots_type: 1,
          building_restriction: {
            "0": 5,
          },
          order_date: this.datePipe.transform(
            new Date(this.departureDate),
            "yyyy/MM/dd"
          ),
          country_code: "91",
          flight_number: localStorage.getItem("pnrValue"),
        };

        this.service
          .createBooking(
            reqBody,
            this.updatedJson[count].departure_bag.departureToken
          )
          .pipe(throttleTime(250))
          .subscribe(
            (data: any) => {
              if (data.status) {
                this.isBooking = true;
                departure_counter += 1;
                this.order = JSON.parse(data._body);
                this.order_array.push({
                  orderId: this.order["order_number"],
                  serviceType: 2,
                  airportId: this.departureAirport_id,
                });
                localStorage.setItem(
                  "OrderDetails_V",
                  JSON.stringify(this.order_array)
                );
                if (
                  this.arrivalCity_id != "" &&
                  this.updatedJson[count].selected_arrival == true
                ) {
                  const reqBody = {
                    // order_type: 3,
                    transfer_type: 2,
                    airport_name: this.arrivalAirport_id,
                    corporate_type: 4,
                    city_name: this.arrivalCity_id,
                    state_name: 0,
                    excess_weight_purchased: "no",
                    excess_weight: 0,
                    bag_weight: 0,
                    no_of_units: this.updatedJson[count].arrival_bag.bag,
                    email: this.updatedJson[count].email,
                    pincode: this.arrivalCity_pincode,
                    items_order: this.updatedJson[count].bagList_arrival,
                    gst_amount: this.updatedJson[count].arrival_bag_gst,
                    luggage_price: this.updatedJson[count].arrival_bag_amount,
                    // priceDetails.price_details.price_with_gst
                    total_luggage_price: this.calculatedAmount,
                    outstation_charge: 0,
                    excess_bag_amount: 0,
                    service_type: 2, // 1 for arrival, //2 for departure
                    pickup_slot: 1,
                    travell_passenger_name: this.updatedJson[count].name,
                    travell_passenger_contact:
                      this.updatedJson[count].mobile.length > 10
                        ? this.updatedJson[count].mobile.slice(-10)
                        : this.updatedJson[count].mobile,
                    pick_drop_spots_type: 1,
                    building_restriction: {
                      "0": 5,
                    },
                    order_date: this.datePipe.transform(
                      new Date(this.arrivalDate),
                      "yyyy/MM/dd"
                    ),
                    country_code: "91",
                    flight_number: localStorage.getItem("pnrValue"),
                  };

                  this.service
                    .createBooking(
                      reqBody,
                      this.updatedJson[count].arrival_bag.arrivalToken
                    )
                    .pipe(throttleTime(250))
                    .subscribe(
                      (data: any) => {
                        if (data.status) {
                          this.isBooking = true;
                          arrival_counter += 1;
                          this.order = JSON.parse(data._body);
                          this.order_array.push({
                            orderId: this.order["order_number"],
                            serviceType: 2,
                            airportId: this.arrivalAirport_id,
                          });
                          localStorage.setItem(
                            "OrderDetails_V",
                            JSON.stringify(this.order_array)
                          );
                          count < this.updatedJson.length
                            ? this.createBooking(count + 1)
                            : ((document.getElementById(
                                "loader"
                              ).style.display = "none"),
                              this.router.navigate([
                                "order-confirmation-page",
                              ]));
                        } else {
                          document.getElementById("loader").style.display =
                            "none";
                        }
                      },
                      (err: any) => {
                        console.log("err");
                        document.getElementById("loader").style.display =
                          "none";
                      }
                    );
                } else {
                  count < this.updatedJson.length
                    ? this.createBooking(count + 1)
                    : ((document.getElementById("loader").style.display =
                        "block"),
                      this.router.navigate(["order-confirmation-page"]));
                }
              }
            },
            (err: any) => {
              this.isBooking = false;
            }
          );
      } else if (
        this.arrivalCity_id != "" &&
        this.updatedJson[count].selected_arrival == true
      ) {
        const reqBody = {
          // order_type: 3,
          transfer_type: 2,
          airport_name: this.arrivalAirport_id,
          corporate_type: 4,
          city_name: this.arrivalCity_id,
          state_name: 0,
          excess_weight_purchased: "no",
          excess_weight: 0,
          bag_weight: 0,
          no_of_units: this.updatedJson[count].arrival_bag.bag,
          email: this.updatedJson[count].email,
          pincode: this.arrivalCity_pincode,
          items_order: this.updatedJson[count].bagList_arrival,
          gst_amount: this.updatedJson[count].arrival_bag_gst,
          luggage_price: this.updatedJson[count].arrival_bag_amount,
          // priceDetails.price_details.price_with_gst
          total_luggage_price: this.calculatedAmount,
          outstation_charge: 0,
          excess_bag_amount: 0,
          service_type: 2, // 1 for arrival, //2 for departure
          pickup_slot: 1,
          travell_passenger_name: this.updatedJson[count].name,
          travell_passenger_contact:
            this.updatedJson[count].mobile.length > 10
              ? this.updatedJson[count].mobile.slice(-10)
              : this.updatedJson[count].mobile,
          pick_drop_spots_type: 1,
          building_restriction: {
            "0": 5,
          },
          order_date: this.datePipe.transform(
            new Date(this.arrivalDate),
            "yyyy/MM/dd"
          ),
          country_code: "91",
          flight_number: localStorage.getItem("pnrValue"),
        };

        this.service
          .createBooking(
            reqBody,
            this.updatedJson[count].arrival_bag.arrivalToken
          )
          .pipe(throttleTime(250))
          .subscribe(
            (data: any) => {
              if (data.status) {
                this.isBooking = true;
                arrival_counter += 1;
                this.order = JSON.parse(data._body);
                this.order_array.push({
                  orderId: this.order["order_number"],
                  serviceType: 2,
                  airportId: this.arrivalAirport_id,
                });
                localStorage.setItem(
                  "OrderDetails_V",
                  JSON.stringify(this.order_array)
                );
                count < this.updatedJson.length
                  ? this.createBooking(count + 1)
                  : ((document.getElementById("loader").style.display = "none"),
                    this.router.navigate(["order-confirmation-page"]));
              }
            },
            (err: any) => {}
          );
      } else {
        count < this.updatedJson.length
          ? this.createBooking(count + 1)
          : ((document.getElementById("loader").style.display = "none"),
            this.router.navigate(["order-confirmation-page"]));
      }
    } else {
      count < this.updatedJson.length
        ? this.createBooking(count + 1)
        : ((document.getElementById("loader").style.display = "none"),
          this.router.navigate(["order-confirmation-page"]));
    }
    if (this.departureCityAirpotDetails != "") {
      localStorage.setItem("departure", this.departureCityAirpotDetails);
    } else {
      localStorage.setItem("departure", this.arrivalCityAirpotDetails);
    }
    // setTimeout(
    //   ()=>{
    //     (count < this.updatedJson.length && this.isBooking === true) ? this.createBooking(count+1) : (document.getElementById('loader').style.display = 'block', this.router.navigate(['order-confirmation-page']));
    //   },(this.updatedJson.length === 1 ? 10000 : 4000*count)
    // )
  }

  //toggle
  setToggle(e, type, data, index) {
    this.selectedBookingData = [];
    if (e.target.checked === false) {
      this.users_details.forEach((user) => {
        if (user["passengerID"] == data.PassengerID && type == "departure")
          user.selected_departure = false;
        else if (user["passengerID"] === data.PassengerID && type === "arrival")
          user.selected_arrival = false;
      });
      $(".dwdr" + index).prop("checked", false);
    } else if (e.target.checked === true) {
      this.users_details.forEach((user) => {
        if (user["passengerID"] == data.PassengerID && type == "departure")
          user.selected_departure = true;
        else if (user["passengerID"] == data.PassengerID && type == "arrival")
          user.selected_arrival = true;
      });
    }
    this.calculatingAmout();
  }

  setToggling(e, data, index) {
    this.selectedBookingData = [];
    if (e.target.checked === false) {
      this.users_details.forEach((user) => {
        if (user["passengerID"] == data.PassengerID) {
          user.selected_departure = false;
          // else if (user["passengerID"] === data.PassengerID)
          user.selected_arrival = false;
          // this.journeyDetailsConnect.ArrivalStation = 'POI';
          $(".dwer" + index).prop("checked", false);
          $(".dwer2" + index).prop("checked", false);
        }
      });
    } else if (e.target.checked === true) {
      this.users_details.forEach((user) => {
        if (user["passengerID"] == data.PassengerID) {
          user.selected_departure = true;
          // else if (user["passengerID"] == data.PassengerID)
          user.selected_arrival = true;
          $(".dwer" + index).prop("checked", true);
          $(".dwer2" + index).prop("checked", true);
        }
      });
    }
    this.calculatingAmout();
  }

  uncheck() {}

  selectedDataHandler(item, type, airportId, price, position, index) {
    this.selectedBookingData.push({
      id: item.passengerID,
      name: item.name,
      type: type,
      airportId: airportId,
      price: price,
      position: position,
      index: index,
    });
    if ($(".dwer:checked").length == this.selectedBookingData.length) {
      this.calculateTotalAmount(this.selectedBookingData);
    }
  }

  calculateTotalAmount(data) {
    this.totalAmount_V = 0;
    let PassengersData = null;
    let filterData = null;
    filterData = data.map((v, i, a) => a.filter((t) => t.id === v.id));
    PassengersData = _.unionWith(filterData, _.isEqual);
    for (const value of PassengersData) {
      if (value.length > 1) {
        this.totalAmount_V =
          this.totalAmount_V +
          value[0].price +
          value[1].price -
          ((value[0].price + value[1].price) * 1) / 100;
      } else {
        this.totalAmount_V = this.totalAmount_V + value[0].price;
      }
    }
    this.AmountOutput.emit(this.totalAmount_V);
    console.log("totalAmount_VOne", this.totalAmount_V);
  }
}
