import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { ReactiveFormsModule } from '@angular/forms';
// import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import {SpicejetService} from './spicejet.service';
import { NgxSoapModule, NgxSoapService } from 'ngx-soap';
import { OneWayComponent } from './one-way/one-way.component';
import { RoundWayComponent } from './round-way/round-way.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './loader/loader.component';
import { ConnectingFlightComponent } from './connecting-flight/connecting-flight.component';
import { DatePipe } from '@angular/common';
import { TimelineComponent } from './timeline/timeline.component';
import { BookingConfirmationComponent } from './booking-confirmation/booking-confirmation.component';
import {UtilService} from "./util.service";
import { BookingInformationComponent } from './booking-information/booking-information.component';
import { CancelandrefundComponent } from './cancelandrefund/cancelandrefund.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { FAQComponent } from './faq/faq.component';
import { FaqContentComponent } from './faq/faq-content/faq-content.component'
// import { NgxUiLoaderModule } from "ngx-ui-loader";


@NgModule({
  declarations: [
    AppComponent,
    LandingpageComponent,
    HeaderComponent,
    FooterComponent,
    BookingDetailsComponent,
    OneWayComponent,
    RoundWayComponent,
    LoaderComponent,
    ConnectingFlightComponent,
    TimelineComponent,
    BookingConfirmationComponent,
    BookingInformationComponent,
    CancelandrefundComponent,
    TermsandconditionsComponent,
    FAQComponent,
    FaqContentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpModule,
    NgxSoapModule,
    BrowserAnimationsModule, // required animations module
    // ToastrModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    // NgxUiLoaderModule,
  ],
  providers: [SpicejetService,NgxSoapService,DatePipe,UtilService],
  bootstrap: [AppComponent]
})
export class AppModule { }
